import {getSnapshot} from 'mobx-keystone'
import React, {useEffect, useState} from 'react'
import {RootStore} from '../models/store/root-store'
import {setupRootStore} from '../models/store/root-store-setup'

export const Debug: React.FC = () => {
  const [rootStore, setRootStore] = useState<RootStore | undefined>()

  useEffect(() => {
    console.log('Setting up root store...')
    setupRootStore({}).then(setRootStore)
  }, [])

  return <pre className="text-sm">{JSON.stringify(getSnapshot(rootStore), null, 2)}</pre>
}
