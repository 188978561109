import dynamic from 'next/dynamic'
import {AppProps} from './core/types'

export const DynamicApp = dynamic<AppProps>(() =>
  import('./core/app').then((lib) => lib.App),
)

export const DynamicDebug = dynamic<unknown>(() =>
  import('./core/debug').then((lib) => lib.Debug),
)
