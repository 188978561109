import Bowser from 'bowser'

const browser = typeof window != 'undefined'

export const isMacOs = () => {
  if (browser) {
    const bowser = Bowser.getParser(navigator.userAgent)
    return bowser.getOSName() === 'macOS'
  }

  return false
}

export const isElectron = () => {
  if (browser) {
    return /Electron/i.test(navigator.userAgent)
  }

  return false
}
